import React from "react";
import rateinsiderLogo from '../images/logo.png'

export const Navbar = ({ phoneNumber = null }) => {
  return (
    <>
      <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
        <div className="relative flex flex-row md:content-center items-center justify-between space-y-2 md:space-y-0">
          <span
            aria-label="Company"
            title="Company"
            className="inline-flex items-center justify-center md:justify-start"
          >
            <img src={rateinsiderLogo} alt="Brand logo" className="w-auto md:w-full h-16"/>
          </span>
          {
            phoneNumber && (
              <a
                href={`tel: ${phoneNumber}`}
                className="inline-flex items-center justify-center h-12 px-4 md:px-6 font-medium tracking-wide text-sm md:text-base text-white transition duration-200 rounded shadow-md bg-indigo-500 hover:bg-indigo-700 focus:shadow-outline focus:outline-none"
                aria-label={`Call ${phoneNumber}`}
                title={`Call ${phoneNumber}`}
              >
                Call {phoneNumber}
              </a>
            )
          }
        </div>
      </div>
      <hr style={{ borderColor: '#E2E8F0' }}/>
    </>
  )
};
