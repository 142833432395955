import queryString from "query-string";

export const queryHandler = location => {
  const query = location.search || '';
  const queryObj = queryString.parse(query) || {};
  const utm_source = queryObj.gclid || queryObj.gbraid || queryObj.wbraid || queryObj.msclkid || queryObj.fbclid || queryObj.ttclid || 'direct';
  return {
    ...queryObj,
    utm_source,
    clientRoute: location.href
  };
  return {}
};
