import React, { useState, Fragment } from "react";
import { queryHandler } from "../foundation/query.handler";
import queryString from "query-string";
import heroImage from '../images/hero.png';

export const Hero = ({ navigate, location, paths, heading }) => {
  const [state, setState] = useState({
    error: "",
    zip: "",
    ...queryHandler(location)
  });

  const updateData = data => setState(prevState => ({ ...prevState, ...data }));
  const handleOnInvalid = e =>
    e.target.setCustomValidity("Please enter zip code");
  const handleOnInput = e => {
    e.target.setCustomValidity("");
    updateData({ error: "" });
  };
  const handleOnChange = e => {
    const { name, value } = e.target;
    updateData({ [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const zip = new FormData(e.target).get("zip");
    if (zip.length < 5) {
      updateData({ error: "Invalid zip code" });
      e.preventDefault();
    } else {
      const { error, clientRoute, utm_source, ...restState } = state;
      const queryStringParams = queryString.stringify({ zip: state.zip, ...restState});

      navigate(`${paths.quotes}/?${queryStringParams}`);
      if (typeof window !== "undefined") {
        window.open(`${paths.rates}/?${queryStringParams}`, '_blank').focus();
      }
    }
  };

  const { zip, utm_source, error } = state;

  return (
    <div className="rounded">
      <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
        <div className="mt-8">
          <div className="relative flex flex-col items-center bg-white xl:flex-row border rounded-lg" style={{ borderColor: '#E2E8F0' }}>
            <div className="w-full px-6 py-8 sm:p-12 xl:w-1/2">
              <h3 className="mb-1 text-2xl font-extrabold leading-none sm:text-3xl">
                {heading.primary.first} {heading.primary.second ? (<><br/>{heading.primary.second}</>) : <Fragment/>}
              </h3>
              <p className="mb-6 text-base text-gray-700 sm:text-base md:text-lg lg:text-base xl:text-lg">
                {heading.secondary}
              </p>
              <form method="get" onSubmit={handleSubmit}>
                <label htmlFor="zip" className="inline-block mb-2 font-semibold">Start Here</label>
                <input
                  id="zip"
                  name="zip"
                  placeholder="Enter your zip code"
                  type="tel"
                  size="5"
                  required
                  value={zip}
                  className="flex-grow w-full h-12 px-4 mb-4 transition duration-200 bg-white border border-gray-400 rounded shadow-sm appearance-none focus:outline-none focus:shadow-outline focus:border-indigo-500"
                  style={{ appearance: 'none'}}
                  onInput={handleOnInput}
                  onChange={handleOnChange}
                  onInvalid={handleOnInvalid}
                />
                <input type="hidden" name="utm_source" value={utm_source} />
                <p>{error && error}</p>
                <button
                  type="submit"
                  className="px-2 h-12 inline-flex items-center justify-center w-full mb-2 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-indigo-600 hover:bg-indigo-700 focus:shadow-outline focus:outline-none"
                >
                  Continue
                </button>
                <p className="text-sm text-gray-700">
                  Find the best savings easily and fast
                </p>
              </form>
            </div>
            <div className="flex w-full xl:w-1/2">
              <object className="relative hidden xl:block">
                <svg
                  viewBox="0 0 190 1440"
                  className="w-16 h-full"
                  style={{ color: '#e2e8f0'}}
                  preserveAspectRatio="none"
                  strokeWidth="0">
                  <path
                    d="M-0.000203125 6.34766e-06H190V1440H-0.000203125C-0.000203125 1440 113.574 1059.66 114.317 699.623C115.06 339.589 -0.000203125 6.34766e-06 -0.000203125 6.34766e-06Z"
                    fill="currentColor"
                  />
                </svg>
              </object>
              <div style={{ background: '#e2e8f0', borderTopRightRadius: '0.5em'}}>
                <div className="py-2 pl-1 lg:py-0 sm:pl-2 xl:pl-0" style={{ background: '#e2e8f0'}}>
                  <div className="w-full transform xl:mt-12 lg:scale-110 xl:scale-125">
                    <img
                      src={heroImage}
                      alt="Hero image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}